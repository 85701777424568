.profile-section {
    color: #fff;
    background: linear-gradient(to right, #2D323E 0%, #3C4252 100%);
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
    background-size: cover;
    background-color: #2D323E;
}

.profile-pic img {
    width: 250px;
    height: 300px;
    object-fit: scale-down;
}

.title-name {
    font-size: 48px;
    font-family: 'Muli', Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 300;
    line-height: 1.2;
}

.title-address {
    font-size: 2rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 1px;
}
.title-address a{
    color: #fff;
}
.flex-bloc{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-bloc.justify-content-start{
    justify-content: left;
}
.flex-bloc.align-start{
    align-items: flex-start;
}
.profile-info2 {
    display: inline-block;
}
.representation {
    /* float: right; */
    margin-top: 0px;;
}
.actor-wr{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 6.4rem;
}
.actor-wr .act-tit{
    width: 100%;
    font-size: 2rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.8px;
}
.rang-wrp{
    width: 100%;
}
.rang-wrp .sect{
    margin-bottom: 1.6rem;
    padding-right: 15px;
}
.rang-wrp .sect:last-child{
    margin-bottom: 0;
}
.rang-wrp .sect .break-down .flet-wrp{
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}
.rang-wrp .sect .break-down .flet-wrp:last-child{
    margin-right: 0px;
}
.lik-werp{
    width: 100%;
    margin-bottom: 15px;
}
.lik-werp h6{
    font-size: 1.4rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.6px;
    margin: 0 0 5px;
}
.lik-werp a{
    font-size: 20px;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.43;
    color: #29b6f6;
    cursor: pointer;
}
.tab-blos{
    width: 100%;
    display: inline-block;
    position: relative;
}
.tbs-ul{
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
}
.tbs-ul li{
    padding: 6px 12px;
    overflow: hidden;
    position: relative;
    font-size: 1.4rem;
    max-width: 264px;
    min-width: 72px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.8;
    white-space: normal;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.tbs-ul li.active{
    color: #039be5;
    border-bottom: 1px solid #039be5;
}
.blo-tabs{
    padding: 24px;
}
.resu-wr{
    display: flex;
    width: 100%;
}
.resum-blo{
    width:100%
}
.titl-wrp{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titl-wrp .res-tit{
    font-size: 2.4rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.235;
    color: #2D323E;
}
.titl-wrp .bt-grops{
    display: flex;
    align-items: center;
}
.titl-wrp .bt-grops .btns{
    padding: 6px 16px;
    font-size: 1.4rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    border:0;
}
.titl-wrp .bt-grops .btns.btn-transprent{
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
}
.titl-wrp .bt-grops .btns.btn-black{
    background: #3C4252;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.title-edit{
    display: flex;
}
.title-edit a{
    font-size: 25px;
}
/* .table-viwe{
    width: 100%;
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.table-viwe.ordering{
    cursor: grab;
}
.table-viwe .table-header{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
}
.table-viwe .table-header .table-title{
    font-size: 2.4rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 300;
    line-height: 1.334;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
}
.table-viwe .table-header .table-title i{
    /* font-size: 1.5rem; 
    color: rgba(0, 0, 0, 0.87);
    margin-right: 15px;
}
.table-viwe .table-header .btns{
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 1.4rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
}
.table-viwe .table-header .btns.btn-transprent{
    border:0;
    background: transparent;
}
.table-viwe .eTpMKP table{
    width: 100%;
    border:0;
}
.table-viwe .eTpMKP table thead tr th{
    font-weight: 700;
    border:0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    line-height: 1.5;
}
.table-viwe .eTpMKP table tbody tr td{
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 15px 5px;
    font-size: 15px;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color:rgba(0, 0, 0, 0.87);
}
.btn-dele{
    background: transparent;
    border:0;
    margin: 0 auto;
    display: block;
    cursor: pointer;
}
.btn-dropDr{
    background: transparent;
    border:0;
    /* margin: 0 auto; */
    /* display: block; 
    cursor: pointer;
} */
/* .table-viwe .eTpMKP table tbody tr td.box-drag{
    cursor: pointer;
}
.table-viwe .eTpMKP table thead tr th.display-show,
.table-viwe .eTpMKP table tbody tr td.display-show{
    display: table-cell;
}
.table-viwe .eTpMKP table thead tr th.display-none,
.table-viwe .eTpMKP table tbody tr td.display-none{
    display: none;
}

.table-viwe ul{
    padding: 15px 0 0;
    list-style: none;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    width: 100%;
}
.table-viwe ul li{
    display: flex;
    width: 100%;
   position: relative;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   padding: 10px 15px;
   border-bottom:1px solid rgba(0, 0, 0, 0.12)
}
.table-viwe ul li .drag-buton{
    background: transparent;
    border: 0;
    cursor: move;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 15px;
    font-size: 1.5rem;
}
.table-viwe ul li .drag-buton.display-none{
    display: none;
}
.table-viwe ul li i.arr-icon{ */
    /* cursor: pointer;
    vertical-align: middle;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center; */
/* }
.table-viwe ul li span{
    font-size: 2rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.6;
    width: 100%;
}
.table-viwe ul li span button{
    display: unset;
    float: right;
    width: 25px;
    padding: 0;
    text-align: center;
}
.table-viwe ul li:last-child{
    border-bottom: 0;
} */
.profile-up h5{
    font-size: 2.4rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.334;
}
.profile-up p{
    font-size: 1.4rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;
}
.image-div{
    width: 300px;
    height: 300px;
    display: inline-block;
    /* display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-wrap: wrap; */
    padding: 1rem 0;
    margin: 15px 0;
}
.image-div img{
    width: 300px;
    height: 300px;
    object-fit: scale-down;
}
.profile-up .btns-groups{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
}
.profile-up .btns-groups .btn-default2{
    padding: 5px 15px;
    color: #039be5;
    background: transparent;
    border: 1px solid rgba(3, 155, 229, 0.5);
    font-size: 1.4rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    margin-left: 15px;
}
.profile-up .btns-groups .btn-default2:first-child{
    margin-left: 0;
}
.profile-up .btns-groups .btn-default2:disabled{
    opacity: 0.5;
    cursor: no-drop;
}
.upload-section{
    width: 100%;
    max-width: 900px;
    margin: 25px auto;
    display: block;
    position: relative;
}

.upload-section input[type="file"]{
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.upload-section label{
    width: 100%;
    height: 180px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed grey;
    border-radius: 5px;
}

.upload-section label i{
    font-size: 28px;
    color: #000;
}
.upload-section label p{
    font-size: 2rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 600;
    line-height: 1.6;
    margin-top: 15px;
}
.visible-mob{
    display: none;
}
@media(max-width: 767px){
    .visible-mob{
        display: block;
    }
    .hide-mob{
        display: none;
    }
    .title-address{
        font-size: 1.7rem;
    }
    .title-name{
        font-size: 35px;
    }
    .title-edit a{
        margin-left: 8px;
    }
    .blo-tabs.tab-resume{
        padding: 24px 0;
    }
    .blo-tabs.tab-resume .resum-blo > div [class*=col-],
    .bg-company .container-fluid > .row > [class*=col-]{
        padding: 0px 0;
    }
    .titl-wrp{
        flex-wrap: wrap;
    }
    .titl-wrp .bt-grops .btns{
        font-size: 1.25rem;
    }
    .table-viwe .table-header .table-title{
        font-size: 2.2rem;
    }
    .profile-detail-mob{
        flex-wrap: wrap;
    }
    .profile-detail-mob .title-address{
        font-size: 15px;
    }
    .actor-wr{
        margin-top: 3rem;
    }
    .profile-detail-mob .title-address.email-address a{
        word-break: break-word;
    }
}
