.bg_verify{
    width: 100%;
    /* height: calc(100% - 140px); */
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(rgb(22, 25, 31) 0%, #2D323E 80%);
}
.veryfy_wrap{
    width: 100%;
    max-width: 500px;
    display: inline-block;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    overflow: hidden;
    padding: 3.2rem;
    text-align: center;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.veryfy_wrap img{
    margin: 3.2rem 0; 
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
.veryfy_wrap .ver-title{
    font-size: 18px;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.75;
    color: rgba(0, 0, 0, 0.87);
}
.veryfy_wrap .ver-text{
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 15px;
}
.veryfy_wrap button{
    color: #fff;
    background-color: #3C4252;
    padding: 4px 10px;
    font-size: 16px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    border:0;
    margin-top: 25px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.veryfy_wrap button:hover{
    background-color: #2D323E;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.bg_steps{
    width: 100%;
    padding: 50px 0;
}
.steps_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    background: #fff;
    border-radius: 5px;
    padding: 25px;
}
.step_conetnt{
    width: 100%;
    display: block;
    padding-top: 20px;
}
.step_conetnt form{
    margin-top: 45px;
}
.btn-gr{
    margin-top: 30px;
}
.label-title{
    font-size: 20px;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 15px;
}
.input_groups label{
    margin-right: 15px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
}
.input_groups label:last-child{
    margin-right: 0px;
}
.input_groups label input{
    margin-right: 5px;
    width:19px;
    height: 19px;
    vertical-align: middle;
    margin-top: 0;
}

.file-wra{
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 25px 0;
}
.table-viwe{
    margin-top: 25px;
}
.layout_tables{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}