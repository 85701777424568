
.bg-myactor{
    width: 100%;
    display: inline-block;
    padding: 30px 0;
}
.header_wrap{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;
}
.page_header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.page_header h4{
    font-size: 3.4rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.235;
    color: rgb(0 0 0 /50%);
}
.bg-myactor .table_wrap{
    /* padding: 25px; */
    width: 100%;
    display: inline-block;
    position: relative;
    /* background: #FFF;
    border-radius: 5px; */
}
.bg-myactor .table_wrap .searc_withbutton{
    position: relative;
    width: 100%;
    display: inline-flex;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    margin-bottom: 25px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.bg-myactor .table_wrap .customSearch{
    position: relative;
    width: 70%;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0
};
.bg-myactor .table_wrap .searc_withbutton .btns_group{
    /* width: 70%; */
    display: inline-flex;
}
.bg-myactor .table_wrap .searc_withbutton .btns_group button{
    margin: 0 5px;
}
.bg-myactor .table_wrap .searc_withbutton .btns_group button:first-child{
    margin-left: 0;
}
.bg-myactor .table_wrap .searc_withbutton .btns_group button:last-child{
    margin-right: 0;
}