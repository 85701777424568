.search-wrap {
    width: 100%;
    display: inline-block;
    position: relative;
}

.search_title {
    font-size: 2.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.334;
    color: rgba(0, 0, 0, 0.87);
}

.card-wrap {
    width: 100%;
    display: inline-block;
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.search-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(0 0 0 / 20%);
}

.search-block .input_group {
    width: 100%;
    display: inline-block;
    position: relative;
}

.search-block .input_group input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 15px 6px 35px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 0;
    border-radius: 4px;
    /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%); */
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.search-block .input_group .search_icon {
    position: absolute;
    top: 7px;
    left: 11px;
}

.search-block .block-select {
    width: 100%;
    max-width: 250px;
    border: 0;
    border-bottom: 1px;
    margin-right: 5px;
}

.search-block .horz-hr {
    height: 28px;
    margin: 4px;
    width: 1px;
    border: none;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.search-block .btn_search {
    border: 0;
    margin-right: 5px;
    color: #3C4252;
    font-size: 1.4rem;
    min-width: 100px;
    display: inline-block;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 10px;
    background: transparent;
}
.custom-accordion-with-button{
    width: 100%;
    position: relative;
}
.custom-position-btn{
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translate(0, -50%);
    z-index: 99;
}
.btn-acro {
    width: 100%;
    text-align: left;
    background: transparent;
    border: 0;
    padding: 12px 15px;
    position: relative;
}

.btn-acro span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

.btn-acro span i,
.btn-acro span .accrodion-icon {
    font-size: 20px;
    transform: rotate(0);
    transition: transform 0.3s linear;
}
.btn-acro.active .accrodion-icon{
    transform: rotate(-180deg);
}

.tabs-bl {
    padding: 15px;
}

.actor-wrap {
    width: 100%;
    min-height: 60vh;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: flex-start;
    border-radius: 4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    margin: 0px 0 25px;
}

.actor-wrap .title-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1.6rem;
    height: 6.4rem;
}

.actor-wrap .title-wrap .tite {
    font-size: 1.6rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

.actor-wrap .title-wrap .lab-badg {
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 4px;
    color: white;
    background: blue;
    padding: 0.4rem 0.8rem;
}

.actor_list {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    display: inline-block;
    position: relative;
    list-style: none;
}

.actor_list li {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0.3rem 0px;
    border-bottom: 1px solid rgb(0 0 0 / 20%);
    padding: 15px 0;
}

.actor_list li:last-child {
    border-bottom: 0;
}

.actor_list li.no-record {
    display: block;
    text-align: center;
}

.actor_list li .actor-list-details {
    width: 70%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: flex-start;
}

.actor_list li .actor-list-details .img-wrap {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 0.8rem;
    padding-right: 0rem;
    margin: 2px;
    width: 80px;
    height: 100px;
}

.actor_list li .actor-list-details .img-wrap img {
    width: auto;
    height: auto;
    max-width: 80px;
    max-height: 100px;
    border-radius: 5px;
}

.actor_list li .actor-list-details .contents {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 0.25rem;
    flex-direction: column;
    display: flex;
    padding-left: 15px;
}

.actor_list li .actor-list-details .contents .actor-title {
    font-size: 2rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

.actor_list li .actor-list-details .contents .address-actor {
    font-size: 1.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
}

.actor_list li .actor-list-details .contents .tag-p {
    margin-top: 10px;
    font-size: 1.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
}

.actor_list li .actor-list-details .contents .tag-p .tag-wrp {
    background-color: transparent;
    height: 24px;
    display: inline-flex;
    color: #3C4252;
    border: 1px solid #3C4252;
    margin: 0px 0.50rem;
    padding-left: 8px;
    padding-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.3rem;
    align-items: center;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
}

.actor_list li .contact-info {
    width: 30%;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 1.6rem;
}

.actor_list li .contact-info p {
    font-size: 1.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
}

.actor_list li .contact-info p a {
    color: rgb(33, 150, 243);
}

.actor_list li .contact-info .group-btn .btn_default2 {
    font-size: 1.4rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 5px 15px;
    color: #039be5;
    border: 1px solid rgba(3, 155, 229, 0.5);
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    align-items: center;
    user-select: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    /* margin-right: 10px; */
    /* align-items: center; */
}

.actor_list li .contact-info .group-btn .btn_default2.border-gray {
    color: #3C4252;
    border: 1px solid rgba(60, 66, 82, 0.5);
    margin-right: 10px;
}

.notes-wrap {
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    margin-top: 60px;
}

.notes-wrap.mt-3 {
    margin-top: 20px;
}

.notes-wrap .note-title {
    font-size: 2.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.334;
    margin-top: 0;
    margin-bottom: 0.35em;
    color: rgba(0, 0, 0, 0.87);
}

.notes-wrap p {
    font-size: 1.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.57);
}

.page-wrap {
    width: 100%;
    text-align: center;
    padding: 0 25px;
}

.label_acrodin {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    background: transparent;
    border: 0;
}
.label_acrodin .icon-accordion{
    transform: rotate(0);
    transition: transform 0.3s linear;
}
.label_acrodin.active .icon-accordion{
    transform: rotate(-180deg);
}
.acrod-wrap {
    width: 100%;
    display: inline-block;
    position: relative;
}

.acrod-wrap .acrod-bloc {
    width: 100%;
    display: inline-block;
    position: relative;
    border-bottom: 1px solid rgb(0 0 0 / 20%);
    padding: 10px 0;
}

.acrod-wrap .acrod-bloc:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.acrod-wrap .acrod-bloc .label_acrodin {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.acrod-wrap .acrod-bloc .label_acrodin .sc-fznJRM {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
}

.acrod-wrap .acrod-bloc .sc-fznJRM h6 {
    font-size: 1.5rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.6;
    color: rgb(0 0 0 / 60%);
}

.acrod-wrap .acrod-bloc .sc-fznJRM h6 small {
    display: block;
    margin-top: 5px;
    font-size: 14px;
}

.acrod-wrap .acrod-bloc .label_acrodin .modal-button {
    display: inline-block;
    position: relative;
}

.acrod-wrap .acrod-bloc .label_acrodin .modal-button.modal-button2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.acrod-wrap .acrod-bloc .label_acrodin .modal-button .btn-default2 {
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0;
    padding: 4px 10px;
    display: inline-block;
    position: relative;
    font-size: 1.3rem;
    margin-left: 10px;
}

.acrod-wrap .acrod-bloc .label_acrodin .modal-button .btn-default2.bg-gray {
    color: #fff;
    background-color: #3C4252;
}

.acrod-wrap .acrod-bloc .label_acrodin i {
    font-size: 18px;
}

.actor-wrap .acrod-bloc .acro-tab {
    padding: 10px;
    width: 100%;
    display: inline-block;
    position: relative;
}

.acto-li {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    display: inline-block;
    position: relative;
    list-style: none;
}

.acto-li li {
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    display: inline-flex;
    font-size: 1.3rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: space-between;
    text-decoration: none;
    padding: 5px 0px 5px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.acto-li li span {
    width: 100%;
}

.acto-li li .btn-dele {
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
    border: 0;
    background: #bdbdbd;
    margin: 0 5px 0 10px;
    border-radius: 20px;
    color: #FFF;
}

.acto-li li .btn-dele:hover {
    background: #9e9c9c;
}

.actor-tag {
    padding: 2px 10px;
    font-size: 15px;
    background-color: #efefef;
    border-radius: 50px;
    margin: 0 10px 10px 0;
}

.visible-mob {
    display: none;
}

.btn_default2 {
    font-size: 1.4rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color .25s cubic-bezier(.4, 0, .2, 1) 0ms, box-shadow .25s cubic-bezier(.4, 0, .2, 1) 0ms, border .25s cubic-bezier(.4, 0, .2, 1) 0ms;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 5px 15px;
    color: #039be5;
    border: 1px solid rgba(3, 155, 229, .5);
    display: -webkit-inline-flex;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    -webkit-align-items: center;
    align-items: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-justify-content: center;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
}

.btn_default2.border-gray {
    color: #3c4252;
    border: 1px solid rgba(60, 66, 82, .5);
    margin-right: 10px;
}

.pagination-btn{}

@media(max-width: 767px) {
    .visible-mob {
        display: block;
    }
    .hide-mob {
        display: none !important;
    }
    .actor_list li .actor-list-details {
        width: 100%;
    }
    .actor_list li .actor-list-details .contents .address-actor {
        white-space: break-spaces;
    }
    .actor_list li .actor-list-details .contents .address-actor .actor-tag {
        display: inline-block;
    }
    .email-field {
        white-space: normal;
        word-break: break-word;
    }
    .title_acrodin.mob-device {
        display: block;
        width: 100%;
    }
    .title_acrodin.mob-device small {
        display: none !important;
    }
    .acrod-wrap .acrod-bloc .label_acrodin {
        flex-wrap: wrap;
    }
}