.jss1 {
    width: 100vw;
    bottom: 0;
    position: absolute;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    margin-top: 12px;
}

.jss1 {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    min-height: 100vh;
}

.hvJMgY {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
}

.hvJMgY .main12 {
    position: relative;
    min-height: 100vh;
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
}

.jss36 {
    color: #fff;
    background: linear-gradient(to right, #2D323E 0%, rgba(22, 25, 31, 0.5) 100%), url(https://images.unsplash.com/photo-1530234332485-f2c7355bd1ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2134&q=80);
    background-size: cover;
}

.bcMPWx {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 2.4rem;
}

.eSbheu {
    display: flex;
    width: 100%;
    flex-direction: column;
    -webkit-box-flex: 0;
    flex-grow: 0;
    color: white;
    padding: 1.6rem;
}

.eSbheu img {
    width: 25.6rem;
    margin-bottom: 3.2rem;
}

.MuiTypography-root {
    margin: 0;
}

.MuiTypography-h3 {
    font-size: 4.8rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.167;
}

.MuiTypography-colorInherit {
    color: inherit;
}

.MuiTypography-subtitle1 {
    font-size: 1.6rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.75;
}

.MuiTypography-colorInherit {
    color: inherit;
}

.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}

.MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.MuiCard-root {
    overflow: hidden;
}

.bJCmFu {
    width: 100%;
    max-width: 40rem;
    margin: 1.6rem auto;
}

.MuiCardContent-root {
    padding: 16px;
}

.MuiCardContent-root:last-child {
    padding-bottom: 24px;
}

.bJCmFu .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    padding: 3.2rem;
}

.MuiTypography-h6 {
    font-size: 2rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.6;
}

.bJCmFu .MuiCardContent-root .MuiTypography-root {
    margin-bottom: 3.2rem;
}

.bJCmFu .MuiCardContent-root form {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
}

.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}

.MuiFormControl-fullWidth {
    width: 100%;
}

.MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1;
}

.MuiInputLabel-root {
    display: block;
    transform-origin: top left;
}

.MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 24px) scale(1);
}

.MuiInputLabel-animated {
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 20px) scale(1);
    pointer-events: none;
}

.MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1.4rem;
    box-sizing: border-box;
    align-items: center;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
}

.MuiInputBase-fullWidth {
    width: 100%;
}

.MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
}

.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    -webkit-tap-highlight-color: transparent;
}

.MuiOutlinedInput-input {
    padding: 18.5px 14px;
}

.MuiInputBase-input:invalid {
    box-shadow: none;
}

.jss125 {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;
}

.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

.jss127 {
    width: auto;
    height: 11px;
    display: block;
    padding: 0;
    font-size: 0.75em;
    max-width: 0.01px;
    text-align: left;
    transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    visibility: hidden;
}

.jss127>span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0;
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #3C4252;
}

.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 1.4rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
}

.MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointer-events: none;
}

.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}

.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.bJCmFu .MuiCardContent-root .no-account {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 3.2rem 0px 2.4rem;
}

.bJCmFu .MuiCardContent-root .no-account .font-medium {
    font-weight: 500;
}

.jss1 {
    width: 100vw;
    bottom: 0;
    position: absolute;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    margin-top: 12px;
}
.eXzlnr {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 0px 0.5rem;
}
.MuiTypography-body2 {
    font-size: 1.4rem;
    font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
    font-weight: 400;
    line-height: 1.43;
}
.bJCmFu .MuiCardContent-root.fflex-start{
    justify-content: start;
}
@media (min-width: 600px) {
    .MuiToolbar-regular {
        min-height: 64px;
    }
}

@media (min-width: 960px) {
    .bcMPWx {
        flex-direction: row;
        padding: 0px;
    }
    .bJCmFu {
        margin: 0px;
    }
    .bJCmFu .MuiCardContent-root {
        padding: 3rem 4.8rem 4.8rem;
    }
    .bJCmFu .MuiCardContent-root .MuiTypography-root {
        width: 100%;
    }
}