.landing-page-nav .navbar-nav>li>a .icon img{
    width: auto;
    height: auto;
}
.landing-page-nav .navbar-nav>li>a {
   font-size: 18px;
   color: #125eac;
   font-weight: 600;
   display: flex;
   align-items: center;
}
.landing-page-nav  .navbar-nav>li>a>span{
    top: 0;
}
.banner-sec{
    background-image: url('./../../../assets/img/banner.png');
    width: 100%;
    min-height: 591px;
    position: relative;
    background-size: contain;
    background-position: bottom right;
    background-color: #125eac;
    background-repeat: no-repeat;
}
.banner-sec > .container{
    min-height: 591px;
    position: relative;
}
.banner-sec .content h1{
    font-size: 48px;
    font-weight: 400;
    color: #fff;
    font-family: 'roboto';
    line-height: 1.2;
    margin-bottom: 21px;
    margin-top: 0;
}
.banner-sec .content p{
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    font-family: 'roboto';
    margin-bottom: 0;
}
.banner-sec .content p a{
    background-color: #87d113;
    display: inline-block;
    padding: 6px 10px;
    text-decoration: underline;
    color: #fff;
    line-height: 1.1;
}
.banner-sec .content p a:hover{
    background-color: #9fe236;
}
.price-sec .btn-primary:hover, .join-revolution .btn-primary:hover{
    background-color: #9fe236;
    border-color: #9fe236;
}
.revolution-actor-sec .revolution-img{
    background-image: url('./../../../assets/img/img-01.png');
    width: 50%;
    position: relative;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
}
.revolution-actor-sec {
    display: flex;
}
.revolution-actor-sec .revolution-content{
    width: 50%;
    background-color: #fff;
    padding: 34px 2% 0 20.3%;
}
.revolution-content h2{
    font-size: 30px;
    color: #3b3b3b;
    font-weight: 700;
    margin-bottom: 17px;
    text-transform: uppercase;
    font-family: 'roboto';
}
.revolution-content h2 span{
    color: #125eac;
}
.revolution-content p{
    font-size: 17px;
    color: #3b3b3b;
    font-weight: 300;
    margin-bottom: 30px;
}
.revolution-content h4{
    color: #125eac;
    font-size: 20px;
    font-weight: 500;
    font-family: 'roboto';
    margin-bottom: 14px;
}
.revolution-content ul{
    padding-left: 0;
}
.revolution-content ul li{
    list-style-type: none;
    margin-bottom: 17px;
    position: relative;
    padding-left: 25px;
    font-size: 17px;
}
.revolution-content ul li:before{
    content: '';
    position: absolute;
    background-image: url('./../../../assets/img/arrow-icon.png');
    width: 23px;
    height: 20px;
    background-position: center;
    background-size: contain;
    left: -5px;
    top: 3px;
}
.price-sec{
    display: flex;
    justify-content: center;
    margin: 53px 0 65px;
    flex-wrap: wrap;
}
.price-sec > div{
    width: 100%;
    text-align: center;
}
.price-sec p{
    font-size: 24px;
    color: #3b3b3b;
    font-weight: 500;
    font-family: 'roboto';
    margin-bottom: 10px;
    text-align: center;
}
.price-sec .btn-primary,
.join-revolution .btn-primary{
    background-color: #87d113;
    border-color: #87d113;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}
.revolution-actor-sec.revolution-casting-sec .revolution-content{
    padding: 16px 18.3% 0 2.5%;
}

.revolution-actor-sec.revolution-casting-sec .revolution-img{
    background-image: url('./../../../assets/img/img-02.png');
}
.dynamic-talent-sec{
    /* background-image: url('./../../../assets/img/bh-2.png'); */
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    padding: 125px 0 120px;
    background-color: #d6d6d6;
}
.dynamic-talent-sec .talent-block{
    background-color: #fff;
    border-radius: 20px;
    padding: 53px 20px 30px;
    text-align: center;
    height: 100%;
    min-height: 243px;
}
.dynamic-talent-sec .talent-block h4{
    font-weight: 700;
    font-family: 'roboto';
    font-size: 24px;
    color: #125eac;
    text-transform: uppercase;
    min-height: 52px;
}
.dynamic-talent-sec .talent-block p{
    font-weight: 500;
    font-size: 18px;
    color: #828282;
}
.join-revolution{
    background-color: #115dab;
    color: #fff;
    text-align: center;
    padding: 55px 0 70px;
}
.join-revolution h2{
    font-size: 48px;
    color: #fff;
    font-family: 'roboto';
    margin-bottom: 30px;
}
.btn-list li{
    list-style-type: none;
    display: inline-block;
    padding: 0 10px;
}
.join-revolution .btn-list li .btn-primary{
    padding: 12px 60px;
}
.footer-landing{
    position: relative;
    padding: 39px 0 0;
}
.footer-landing h5{
    font-size: 24px;
    color: #003366;
    font-family: 'roboto';
    margin-bottom: 27px;
}
.footer-landing p{
    font-size: 16px;
    color: #828282;
    margin-bottom: 30px;
    max-width: 80%;
}
.footer-landing  ul{
    padding-left: 0;
}
.footer-landing li{
    font-size: 16px;
    color: #828282;
    list-style-type: none;
    margin-bottom: 6px;
}
.footer-landing li a{
    color: #828282;
}
.footer-landing li a:hover{
    color: #3287B2;
}
.foo-btm{
    border-top: 1px solid #a3a3a3;
    margin-top: 6px;
    padding: 26px 0;
}
.foo-btm .fflex{
    display: flex;
    justify-content: space-between;
}
.foo-list li{
    list-style-type: none;
    display: inline-block;
    position: relative;
    font-size: 16px;
    padding-left: 30px;
}
.foo-list{
    margin-bottom: 0;
}
.foo-list li a{
    color: #828282;
}
.foo-list li:before{
    position: absolute;
    content: '';
    height: 90%;
    width: 1px;
    background-color: #828282;
    right: -15px;
    top: 2px;
}
.foo-list li:last-child::before{
    display: none;
}
.foo-btm p{
    margin-bottom: 0;
}
.btn-list{
    padding-left: 0;
}
.banner-sec .content{
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.banner-sec .content p{
    font-size: 35px;
}
@media(max-width: 1500px){
    .revolution-actor-sec .revolution-content{
        padding: 34px 4% 0 8.3%;
    }
    .revolution-actor-sec.revolution-casting-sec .revolution-content {
        padding: 16px 8% 0 3.5%;
    }
    .banner-sec{
        background-size: 90%;
        min-height: 530px;
    }
    .banner-sec > .container {
        min-height: 530px;
    }
}
@media(max-width: 1360px){
    .revolution-actor-sec.revolution-casting-sec .revolution-content {
        padding: 16px 20px 0 2.5%;
    }
    .banner-sec > .container,
    .banner-sec {
        min-height: 470px;
    }
}
@media(max-width: 1199px){
    .banner-sec .content h1{
        font-size: 40px;
    }
    .banner-sec .content p,
    .revolution-content h2 {
        font-size: 24px;
    }
    .banner-sec > .container, .banner-sec {
        min-height: 390px;
    }
    .revolution-actor-sec .revolution-content {
        padding: 24px 2% 0 3.5%;
    }
    .price-sec p {
        font-size: 20px;
    }
    .dynamic-talent-sec{
        padding: 80px 0;
    }
    .dynamic-talent-sec .talent-block h4{
        font-size: 22px;
    }
    .join-revolution h2 {
        font-size: 40px;
    }   
    .footer-landing h5 {
        font-size: 20px;
        margin-bottom: 20px;
    } 
    .footer-landing p{
        margin-bottom: 20px;
    }
}
@media(max-width: 991px){
    .landing-page-nav .navbar-nav>li>a{
        padding: 24px 10px;
        font-size: 16px;
    }
    .banner-sec > .container, .banner-sec {
        min-height: 300px;
    }
    .banner-sec .content h1 {
        font-size: 32px;
    }
    .price-sec{
        flex-wrap: wrap;
    }
    .price-sec .left-sec{
        margin-bottom: 15px;
    }
    .dynamic-talent-sec .talent-block{
        padding: 30px 20px;
        min-height: auto;
        margin-bottom: 30px;
    }
    .dynamic-talent-sec .talent-block h4{
        min-height: auto;
    }
    .dynamic-talent-sec {
        padding: 60px 0 30px;
    }
    .join-revolution h2 {
        font-size: 30px;
    }
    .join-revolution{
        padding: 35px 0 50px;
    }
    .footer-landing{
        padding-top: 20px;
    }
    .footer-landing p{
        max-width: 100%;
    }
    .foo-btm p{
        margin-bottom: 0;
    }
}
@media(max-width: 767px){
    .revolution-actor-sec .revolution-img{
        width: 100%;
        min-height: 250px;
        order: -1;
    }
    .revolution-actor-sec,
    .foo-btm .fflex{
        flex-wrap: wrap;
    }
    .banner-sec .content h1 {
        font-size: 28px;
    }
    .banner-sec .content h1 br,
    .revolution-content h2 br{
        display: none;
    }
    .banner-sec .content p, .revolution-content h2 {
        font-size: 20px;
    }
    .revolution-actor-sec .revolution-content,
    .revolution-actor-sec.revolution-casting-sec .revolution-content{
        width: 100%;
        padding: 10px 15px;
    }
    .price-sec{
        margin: 40px 0;
    }
    .join-revolution h2 {
        font-size: 26px;
    }
    .btn-list li{
        margin-bottom: 15px;
    }
    .join-revolution .btn-list li .btn-primary{
        padding: 10px 40px;
    }
    .foo-btm .fflex{
        text-align: center;
        justify-content: center;
    }
    .foo-btm p{
        margin-bottom: 10px;
    }
    .landing-page-nav .navbar-nav>li>a {
        padding: 12px 6px;
        font-size: 14px;
    }
    .banner-sec {
        background-image: none;
    }
    .banner-sec .content {
        width: 100%;
        left: 0;
        padding: 0 15px;
    }
}
