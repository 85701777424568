.jss1 {
    width: 100vw;
    bottom: 0;
    position: absolute;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    margin-top: 12px;
}

.jss1 {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    min-height: 100vh;
}

.hvJMgY {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
}

.hvJMgY .main12 {
    position: relative;
    min-height: 100vh;
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
}

.jss75 {
    color: #fff;
    background: linear-gradient(to right, #2D323E 0%, rgba(22, 25, 31, 0.5) 100%), url(https://images.unsplash.com/photo-1530234332485-f2c7355bd1ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2134&q=80);
    background-size: cover;
}

.jss217 {
    color: #fff;
    background: linear-gradient(to right, #2D323E 0%, rgba(22, 25, 31, 0.5) 100%), url(https://images.unsplash.com/photo-1530234332485-f2c7355bd1ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2134&q=80);
    background-size: cover;
}

.bcMPWx {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 2.4rem;
}

.eSbheu {
    display: flex;
    width: 100%;
    flex-direction: column;
    -webkit-box-flex: 0;
    flex-grow: 0;
    color: white;
    padding: 1.6rem;
}

.MuiTypography-root {
    margin: 0;
}

.MuiTypography-h3 {
    font-size: 4.8rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.167;
}

.MuiTypography-colorInherit {
    color: inherit;
}

.MuiTypography-subtitle1 {
    font-size: 1.6rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.75;
}

.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}

.MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.MuiCard-root {
    overflow: hidden;
}

.bJCmFu {
    width: 100%;
    max-width: 40rem;
    margin: 1.6rem auto;
}

.bJCmFu .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    padding: 3.2rem;
}

.MuiTypography-h6 {
    font-size: 2rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.6;
}

.bJCmFu .MuiCardContent-root .MuiTypography-root {
    margin-bottom: 3.2rem;
}

.jss337 {
    padding: 9px;
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 2.4rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.custom-checkBox {
    display: block;
    position: relative;
    width: 25px;
    height: 25px;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-checkBox .cus-check {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* .custom-checkBox .cus-check:checked ~ .MuiSvgIcon-root{
    fill: #039be5;
    color: #039be5;
}
.custom-checkBox .cus-check:checked ~ .MuiSvgIcon-root path{
    fill: #039be5;
} */

.custom-checkBox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 5px;
    border: 3px solid rgba(0, 0, 0, 0.54)
}

.custom-checkBox input.cus-check:checked~.checkmark {
    background-color: #039be5;
    border-color: #039be5;
}

.custom-checkBox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkBox input.cus-check:checked~.checkmark:after {
    display: block;
}

.custom-checkBox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (min-width: 960px) {
    .bJCmFu .MuiCardContent-root .MuiTypography-root {
        width: 100%;
    }
    .bJCmFu .MuiCardContent-root .MuiTypography-root {
        margin-bottom: 3.2rem;
    }
    .MuiTypography-h6 {
        font-size: 2rem;
        font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
        font-weight: 600;
        line-height: 1.6;
    }
    .MuiTypography-root {
        margin: 0;
    }
}

h6, .h6 {
    font-size: 12px;
}

* {
    outline: none !important;
}

user agent stylesheet h6 {
    display: block;
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.bJCmFu .MuiCardContent-root form {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
}

.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}

.MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1;
}

.MuiInputLabel-root {
    display: block;
    transform-origin: top left;
}

.MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 24px) scale(1);
}

.MuiInputLabel-animated {
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 20px) scale(1);
    pointer-events: none;
}

.MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1.4rem;
    box-sizing: border-box;
    align-items: center;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
}

.MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
}

.MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointer-events: none;
}

.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}

.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}

.bJCmFu .MuiCardContent-root .no-account {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 3.2rem 0px 2.4rem;
}

.bJCmFu .MuiCardContent-root .no-account .font-medium {
    font-weight: 500;
}

@media (min-width: 960px) {
    .bcMPWx {
        flex-direction: row;
        padding: 0px;
    }
    .bJCmFu {
        margin: 0px;
    }
    .bJCmFu .MuiCardContent-root {
        padding: 12.8rem 4.8rem 4.8rem;
    }
    .bJCmFu .MuiCardContent-root .MuiTypography-root {
        width: 100%;
    }
}