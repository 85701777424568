/*table css*/

.table-viwe {
    width: 100%;
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.table-viwe.ordering {
    cursor: grab;
}

.table-viwe .table-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
}

.table-viwe .table-header .table-title {
    font-size: 2.4rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 300;
    line-height: 1.334;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
}

.table-viwe .table-header .table-title i {
    /* font-size: 1.5rem; */
    color: rgba(0, 0, 0, 0.87);
    margin-right: 15px;
}

.table-viwe .table-header .btns {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 1.4rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
}

.table-viwe .table-header .btns.btn-transprent {
    border: 0;
    background: transparent;
}

.table-viwe .eTpMKP table, .table-custom {
    width: 100%;
    border: 0 !important;
}

.table-viwe .eTpMKP table thead tr th,
.table-custom thead tr th {
    font-weight: 700;
    border: 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    line-height: 1.5;
}

.table-viwe .eTpMKP table tbody tr td,
.table-custom tbody tr td {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 15px 5px;
    font-size: 15px;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
}

.btn-dele {
    background: transparent;
    border: 0;
    margin: 0 auto;
    display: block;
    cursor: pointer;
}

.btn-dropDr {
    background: transparent;
    border: 0;
    /* margin: 0 auto; */
    /* display: block; */
    cursor: pointer;
}

.table-viwe .eTpMKP table tbody tr td.box-drag,
.table-custom tbody tr td.box-drag{
    cursor: pointer;
}

.table-viwe .eTpMKP table thead tr th.display-show, 
.table-viwe .eTpMKP table tbody tr td.display-show,
.table-custom thead tr th.display-show,
.table-custom tbody tr td.display-show{
    display: table-cell;
}

.table-viwe .eTpMKP table thead tr th.display-none, 
.table-viwe .eTpMKP table tbody tr td.display-none,
.table-custom thead tr th.display-none,
.table-custom tbody tr td.display-none {
    display: none;
}

.table-viwe ul {
    padding: 15px 0 0;
    list-style: none;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    width: 100%;
}

.table-viwe ul li {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)
}

.table-viwe ul li .drag-buton {
    background: transparent;
    border: 0;
    cursor: move;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 15px;
    font-size: 1.5rem;
}

.table-viwe ul li .drag-buton.display-none {
    display: none;
}

/* .table-viwe ul li i.arr-icon{ */

/* cursor: pointer;
    vertical-align: middle;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center; */

/* } */

.table-viwe ul li span {
    font-size: 2rem;
    font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.6;
    width: 100%;
}

.table-viwe ul li span button {
    display: unset;
    float: right;
    width: 25px;
    padding: 0;
    text-align: center;
}

.table-viwe ul li:last-child {
    border-bottom: 0;
}

/* table css over */
@media(max-width: 767px){
    .table-viwe .eTpMKP table thead tr th, .table-custom thead tr th{
        font-size: 16px;
        padding: 5px 8px;
    }
    .table-viwe .eTpMKP table tbody tr td, .table-custom tbody tr td{
        padding: 10px 8px;
        font-size: 14px;
    }
    .table-viwe .table-header .table-title{
        font-size: 20px;
    }
    .table-viwe ul li span{
        font-size: 15px;
    }
}